#registry {
  div {
    div {
      @media screen and (min-width: 770px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.registry-link {
  display: block;
  text-align: center;

  @media screen and (min-width: 770px) {
    display: inline-block;
  }

  &:not(:last-child) {
    @media screen and (min-width: 770px) {
      margin-right: 2rem;
    }
  }
}
