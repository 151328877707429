.event {
  font-size: 2rem;
  text-align: center;

  &--ceremony,
  &--reception {
    display: flex;
    flex-direction: column;
  }

  &--heading {
    color: $color-mauve;
    font-size: 5rem;
    margin: 2rem 0;

    @media screen and (min-width: 1200px) {
      margin: 5rem 0;
    }
  }

  &--date,
  &--time,
  &--location {
    font-family: $font-sans-serif;
    margin: 1rem 0;

    @media screen and (min-width: 1200px) {
      margin: 2rem 0;
    }
  }
}
