.date-counter {
  color: white;
  font-family: $font-sans-serif;
  font-size: 2rem;
  padding: 3rem;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }

  p {
    font-size: 4rem;
    margin: 0;

    @media screen and (min-width: 1200px) {
      font-size: 5rem;
    }
  }
}
